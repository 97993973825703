<template>
    <div id="MemberSystem">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'MemberSystem',
}
</script>
<script setup></script>
<style lang="scss" scoped></style>
